// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
::ng-deep .toggled svg .grid,
::ng-deep .toggled svg .list {
  stroke: #414448;
  fill: transparent;
}
::ng-deep .toggled:hover svg .grid,
::ng-deep .toggled:hover svg .list {
  stroke: #ffffff;
}

.image-card {
  max-height: 170px;
  height: 170px;
}

.image-card-container {
  height: 165px;
}

.title-card-container {
  height: 90px;
}

.shadow-card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.3s;
}

.shadow-card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.star-icon {
  font-size: 22px;
  color: #f0ad4e;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
