import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Constants } from '../helpers';
import { AuthService } from '../services/auth.service';
import { RolePermissionsService } from '../services/role-permissions.service';

@Injectable()
export class AuthGuard {
	constructor(
		private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private permissionsService: RolePermissionsService
	) {}

	public canActivate(): boolean {
		const isUserAuthenticated: boolean = this.checkUserAuth();

		if (this.permissionsService.isEmployee()) {
			this.router.navigate(['/shifts']);
			return false;
		}

		// For inactive entities, redirect to subscription page
		if (!this.authService.isEntityActive() && this.authService.isEntityOwner()) {
			this.router.navigate([Constants.ROUTE_LINKS.renewSubscription]);

			return false;
		}

		return isUserAuthenticated;
	}

	private checkUserAuth(): boolean {
		if (this.authService.isAuthenticated()) {
			return true;
		}

		const urlList = location.hash.split('/');
		this.route.queryParams.subscribe(params => {
			return false;
		});

		if (urlList.length >= 1) {
			this.router.navigate([Constants.ROUTE_LINKS.login], { queryParams: { returnUrl: location.pathname } });
		} else {
			this.router.navigate([Constants.ROUTE_LINKS.login]);
		}
		return false;
	}
}

/**
 * This RouteGuard acts as the inverse of the above, ensuring authenticated users cannot access the login or signup screens
 * while already authenticated. If the user tries to access an unauthenticated route, the guard will redirect them to the user dashboard.
 */
@Injectable()
export class NoAuthGuard {
	constructor(
		private authService: AuthService,
		private router: Router
	) {}

	public canActivate(): boolean {
		//if (this.authService.isAuthenticated()) {
		//	this.router.navigate([Constants.ROUTE_LINKS.dashboard]);
		//	return false;
		//}
		return true;
	}
}
