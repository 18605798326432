import { AfterViewInit, Component, DestroyRef, EventEmitter, inject, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { filter, forkJoin, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { TableComponent } from '../../../../@mos-core/components/table/table.component';
import { Text } from '../../../../@itc-core/common/text.constants';
import { ItcModalConfig } from '../../../../@itc-core/directives/modal/common/modal.models';
import { ButtonIcons } from '../../../components/icon-button/common/icon-button.constants';
import { IconButtonConfig } from '../../../components/icon-button/common/icon-button.interfaces';
import { ArrayHelper, Constants, matAutocompleteDropdownLimit, PipeExportModule, SnackBarHelperComponent, StringHelper, TEXT, ValidatorHelper } from '../../../helpers';
import { ClientSearch, Company, GetClientSearchValidation, State, StateAutocompleteItemInterface } from '../../../interfaces';
import { CompanyModel, UserModel } from '../../../models';
import { ClientService } from '../../../services/client.service';
import { CompanyService, GetCompaniesResolver } from '../../../services/company.service';
import { NavigationService } from '../../../services/navigation.service';
import { RolePermissionsService } from '../../../services/role-permissions.service';
import { GetCurrentUserResolver } from '../../../services/user.service';
import { MeasuringToolLayoutTextConst } from '../../measure-tool/common/components/measuring-tool-layout/common/measuring-tool-layout.constants';
import { CustomSearchEvent } from '../../project/project-search/common/project-totals.interface';
import { CompanyListText } from './common/company-list.constants';
import { ClientSearchIsValidFields } from './common/company-list.interface';

// Replaces state service, caches states
import { CacheService } from '../../../services/cache.service';
import { getSteps as defaultSteps } from './common/company-list.tour.config';
import { ShepherdService } from 'angular-shepherd';
import { ProductTourService } from '../../../services/product-tour.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { S3Service } from '../../../services/s3.service';
import { SlideOverComponent } from '../../../../@mos-core/components/slide-over/slide-over.component';
import { DialogButtonConfig } from '../../../../@mos-core/components/dialog/dialog.component';
import { AddEditUserComponent } from '../../../core/components/add-edit-user/add-edit-user.component';
import { AddEditClientComponent } from '../../../core/components/add-edit-client/add-edit-client.component';
import { SlideOverService } from '../../../services/slide-over.service';
import { ClientCommentPreviewComponent } from '../../../components/client/components/client-comment-preview/client-comment-preview.component';

@Component({
	selector: 'app-company-list',
	templateUrl: './company-list.component.html',
	styleUrls: ['company-list.component.scss'],
})
export class CompanyListComponent implements OnInit, AfterViewInit {
	@ViewChild('addClientSlideOver') public addClientSlideOver!: SlideOverComponent;
	@Output() public saveClient: EventEmitter<any> = new EventEmitter();

	/**
	 * Router config for resolvers
	 * @type {}
	 */
	public static routerCompanyResolvers = {
		companies: GetCompaniesResolver,
		currentUser: GetCurrentUserResolver,
	};

	/**
	 * Router config for resolvers
	 * @type {}
	 */
	public static routerClientResolvers = {
		currentUser: GetCurrentUserResolver,
	};

	/**
	 * Router config for data
	 * @type {}
	 */
	public static routerCompanyData = {
		restrictToClient: false,
	};

	/**
	 * Router config for data
	 * @type {}
	 */
	public static routerClientData = {
		restrictToClient: true,
	};

	public tableLimit: number = 20;

	public tableColumns = {};

	public addTagText = Constants.CUSTOM_SEARCH_TEXT;
	public clientNames: string[] = [];
	public clientSearch: ClientSearch = {
		name: undefined,
		email: '',
		phone: '',
		fax: '',
		city: '',
		state: undefined,
		contact: '',
		isActive: true,
	};
	public clientSearchValidators: any;
	public companies: Company[];
	public contactFilteredOptions: Observable<State[]>;
	public contactNames: string[] = [];
	public filteredContacts: string[];
	public filteredNames: string[];
	public filteredStates: StateAutocompleteItemInterface[];
	public headerText: string = undefined;
	public iconConfig: IconButtonConfig;
	public isContractsAndAbove: boolean = false;
	public isTeamLeadAndAbove: boolean = false;
	public isValidFields: ClientSearchIsValidFields;
	public libraryTypesConst = Constants.LIBRARY_TYPES;
	public loading: boolean = false;
	public loadingCompaniesPromise: Promise<{ items: Company[]; total: number }>;
	public navRoute = '';
	public noItemsId: string = 'no_items';
	public pageNo: number = 0;
	public paginationLimit: number = Constants.PAGINATE_ITEMS_25PER_PAGE;
	public SVG_ICON_PATHS = Constants.SVG_ICON_PATHS;
	public restrictToClient = true;
	// A flag for showing the active items By default we should show all the items
	public showInactive = false;
	public states: any;
	public textConstants = CompanyListText;
	public text: typeof TEXT = TEXT;
	public totalCount = 0;
	public totalPages: number;
	public validators;
	public isTableView: boolean = true;
	public imagePromises: Promise<any>[];
	public displayItems: any[] = [];
	private destroyRef = inject(DestroyRef);
	public formState: string;
	public formStates = Constants.FORM_STATES;
	public selectedClient: Company;

	// toggles whether the "CREATE NEW COMPANY" modal should be shown
	public showCreateCompanyModal: boolean = false;

	public createCompanyModalConfig: ItcModalConfig = new ItcModalConfig();

	public addClientButtons: DialogButtonConfig[] = [
		{ label: 'Save', action: this.emitSaveClient.bind(this), type: 'primary' },
		{ label: 'Close', action: this.saveAndCloseClientSlideOver.bind(this), type: 'normal' },
	];
	item: any;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private companyService: CompanyService,
		public clientService: ClientService,
		private rolesPermissions: RolePermissionsService,
		public snack: SnackBarHelperComponent,
		private navigationService: NavigationService,
		private cacheService: CacheService,
		private shepherdService: ShepherdService,
		private productTourService: ProductTourService,
		private s3Service: S3Service,
		private slideOver: SlideOverService
	) {}

	public ngOnInit(): void {
		this.clientSearchValidators = GetClientSearchValidation();
		this.setPermissions();
		this.getStates();

		this.route.data.subscribe(({ restrictToClient }) => {
			this.restrictToClient = restrictToClient;
			if (this.restrictToClient) {
				this.headerText = 'Client';
				this.navRoute = '/client/';
				this.resetClientSearch();
				this.populateDropdownLists();
			} else {
				this.headerText = 'Companies';
				this.navRoute = '/admin/company/';
			}
			this.setIconConfig();
		});
		this.getNextPage(0);
		this.setIsValidFields();

		this.createCompanyModalConfig = new ItcModalConfig({
			cancelButtonText: Text.cancel,
			confirmButtonText: Text.confirm,
			showCancelButton: true,
			showCloseModalButton: true,
			title: 'Edit Company',
		});

		if (this.restrictToClient) {
			this.tableColumns = {
				ClientName: true,
				Email: true,
				Phone: true,
				Contact: true,
				City: true,
				State: true,
				Comments: true,
			};
		} else {
			this.tableColumns = {
				CompanyName: true,
				Email: true,
				Phone: true,
				City: true,
				State: true,
			};
		}
	}

	public consumeClick(event: MouseEvent): void {
		event.stopPropagation();
		event.preventDefault();
	}

	public ngAfterViewInit() {
		this.shepherdService.defaultStepOptions = {
			scrollTo: true,
			cancelIcon: {
				enabled: true,
			},
		};
		this.shepherdService.modal = true;

		this.productTourService.activeTourSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(tourId => {
			if (!this.shepherdService.isActive) {
				if (tourId === 'Companies' || tourId === 'Clients') {
					this.productTourService.showSteps(defaultSteps(this.shepherdService, tourId), false);
				}
			}
		});

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationStart),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((event: NavigationStart) => {
				if (event.navigationTrigger === 'popstate') {
					if (this.shepherdService.isActive) {
						this.shepherdService.cancel();
					}
				}
			});
	}

	public autocompleteOptionSelected(event: MatAutocompleteSelectedEvent, field: string): void {
		this.clientSearch[field] = event.option.value.id;
		this.isValidFields[field] = this.clientSearch[field] !== undefined;

		this.searchClients();
	}

	public clearFormControl(formControlName: string) {
		this.clientSearchValidators[formControlName].setValue(undefined);
	}

	public delayedClientSearch(): void {
		// For fields that have text typed in but only take dropdown/autocomplete items as valid input, do not search
		if (this.isValidSearchFields()) {
			setTimeout(() => {
				this.searchClients();
			}, 0);
		}
	}

	private getItemImages(): void {
		this.imagePromises = [];
		for (const company of this.companies) {
			if (!company.displayPictureUrl) {
				// Get the url from the S3 key
				if (company.displayPictureKey) {
					this.imagePromises[company.id] = this.s3Service.getSignedUrl(company.displayPictureKey).toPromise();
					this.imagePromises[company.id].then(url => (company.displayPictureUrl = url));
				}
			}
		}
	}

	/**
	 * API call to get next set of values
	 * @param {number} pageSelected
	 */
	public getNextPage(pageSelected: number) {
		this.pageNo = pageSelected;
		const skip = this.pageNo * this.paginationLimit;
		const search = {
			skip: skip,
			limit: this.paginationLimit,
			params: this.clientSearch,
		};

		this.loadingCompaniesPromise = this.restrictToClient ? this.clientService.postPaginate(search).toPromise() : this.companyService.paginateCompany(search).toPromise();

		this.loadingCompaniesPromise.then(async res => {
			this.companies = res.items.map(client => new CompanyModel(client));
			// Patch in state objects from cache service, in company model, and array of contacts
			for (const co of this.companies) {
				if (co.state) {
					const stateId: string = co.state as any;
					co.state = this.cacheService.getStateById(stateId);
					for (const contact of co.contacts) {
						if (contact.state) {
							const stateId: string = contact.state as any;
							contact.state = this.cacheService.getStateById(stateId);
						}
					}
				}
			}
			this.totalCount = res.total;
			this.totalPages = Math.ceil(this.totalCount / this.paginationLimit);
			this.getItemImages();
		});
	}

	/**
	 * Switches list view to display or not display inactive items.
	 */
	public toggleInactive(): void {
		this.showInactive = !this.showInactive;
		this.pageNo = 0;
		this.clientSearch.isActive = this.showInactive ? undefined : true;
		this.getNextPage(0);
	}

	/**
	 * Updates current values using the generic library.
	 * @param event
	 */
	public newPageFromLibrary(page): void {
		this.clientSearch.isActive = this.showInactive ? undefined : true;
		this.getNextPage(page);
	}

	// Navigates on the client page when a children client is clicked.
	public onClientClick(client: Company, isClient?: boolean): void {
		this.selectedClient = client;
		this.addEditCompanyClient(client);
		// if (client) {
		// 	this.navigationService.setRoute([`${this.navRoute}/${client}`]);
		// } else if (isClient) {
		// 	this.router.navigate([this.navRoute]);
		// } else {
		// 	this.navigationService.setRelativeRoute('new', this.route);
		// 	this.showCreateCompanyModal = true;
		// }
	}

	public resetClientSearch() {
		this.clientSearch = {
			name: undefined,
			email: '',
			phone: '',
			fax: '',
			city: '',
			state: undefined,
			isActive: true,
		};

		this.clientSearchValidators.State.setValue(undefined);
		this.clientSearchValidators.Name.setValue(undefined);
		this.clientSearchValidators.Contact.setValue(undefined);

		// re-populate list when search fields are cleared
		this.getNextPage(0);
	}

	/**
	 * Add custom search string to search as a first option
	 * @param customSearchEvent
	 * @param dropdown
	 */
	public addCustomSearchItem(customSearchEvent: CustomSearchEvent, dropdown: string): void {
		if (dropdown === this.textConstants.name) {
			this.filteredNames = customSearchEvent.term ? [customSearchEvent.term, ...this.clientNames] : this.clientNames;
		}
		if (dropdown === this.textConstants.contact) {
			this.filteredContacts = customSearchEvent.term ? [customSearchEvent.term, ...this.contactNames] : this.contactNames;
		}
	}

	public searchClients() {
		this.clientSearch.name = this.clientSearchValidators.Name.value || '';
		this.clientSearch.contact = this.clientSearchValidators.Contact.value || '';
		if (this.clientSearchValidators.State.value) {
			this.clientSearch.state = this.clientSearchValidators.State.value;
		} else {
			delete this.clientSearch.state;
		}

		const errors = ValidatorHelper.checkErrors(this.clientSearchValidators);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}
		this.getNextPage(0);
	}

	/**
	 * sets boolean values for isTeamLeadAnd above, and isContractsAndAbove depending on the current clients role.
	 * @private
	 */
	private setPermissions(): void {
		this.isTeamLeadAndAbove = this.rolesPermissions.isTeamLeaderAndAbove();
		this.isContractsAndAbove = this.isTeamLeadAndAbove ? true : this.rolesPermissions.isContractsAndAbove();
	}

	private isValidSearchFields(): boolean {
		if (!this.isValidFields.state && this.clientSearchValidators.State.value?.length) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Creates distinct lists of formatted titles for dropdown lists
	 */
	private populateDropdownLists(): void {
		// Get a list of clients
		this.loading = true;
		this.clientService
			.getClients()
			.toPromise()
			.then(clients => {
				// Get a list of clients for the name dropdown
				this.clientNames = ArrayHelper.getDistinctArray(clients, client => StringHelper.toTitleCase(client.name), false).sort((a, b) => (a > b ? 1 : -1));
				this.filteredNames = this.clientNames;
				this.loading = false;

				// Get a list of contacts for the contacts dropdown
				let contacts = [];
				clients.forEach(client => {
					contacts = contacts.concat(client.contacts);
				});
				this.contactNames = ArrayHelper.getDistinctArray(contacts, contact => StringHelper.toTitleCase(contact.name), false);
				this.filteredContacts = this.contactNames;
			});
	}

	private setIconConfig(): void {
		if (this.restrictToClient) {
			this.iconConfig = {
				icon: ButtonIcons.user,
				label: this.textConstants.newClient,
				isAddSuffix: true,
				hasBorder: true,
			};
		} else {
			this.iconConfig = {
				icon: null,
				label: this.textConstants.newCompany,
				//isAddSuffix: true,
				hasBorder: true,
			};
		}
	}

	private setIsValidFields(): void {
		this.isValidFields = {
			state: false,
		};
	}

	private getStates(): void {
		this.states = this.cacheService.getStates();
	}

	public addEditCompanyClient(client?: Company): void {
		this.restrictToClient = this.router.url.startsWith('/clients');

		if (client) {
			this.formState = Constants.FORM_STATES.EDIT;
			this.selectedClient = client;
			this.addClientSlideOver.open();
		} else {
			this.selectedClient = null;
			this.formState = Constants.FORM_STATES.ADD;
			this.addClientSlideOver.open();
		}
	}

	public emitSaveClient(): void {
		this.clientService.emitSaveClient();
		this.getNextPage(0);
	}

	public saveAndCloseClientSlideOver(): void {
		this.selectedClient = null;
		this.addClientSlideOver.close();
	}

	public handleCloseSlideOver() {
		this.addClientSlideOver.isOpen = false;
		this.selectedClient = null;
		this.slideOver.close();
	}

	protected readonly MeasuringToolLayoutTextConst = MeasuringToolLayoutTextConst;
}
