import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { ProductTourService } from '../../../../services/product-tour.service';
import { offset } from '@floating-ui/dom';

export const STEPS_BUTTONS = {
	back: {
		classes: 'back-button',
		secondary: true,
		text: 'Back',
		type: 'back',
	},
	cancel: {
		classes: 'cancel-button',
		secondary: true,
		text: 'Exit',
		type: 'cancel',
	},
	next: {
		classes: 'next-button',
		text: 'Next',
		type: 'next',
	},
};

export function getSteps(service: ShepherdService, tourID: string): Step.StepOptions[] {
	if (tourID === 'Companies') {
		tourID = 'Company';
	} else if (tourID === 'Clients') {
		tourID = 'Client';
	}

	const handleNext = () => {
		const navItem = document.querySelector(`.primary`);
		if (navItem) {
			navItem.removeEventListener('click', handleNext);
		}
		service.next();
	};

	return [
		{
			id: 'step3',
			title: `Create New ${tourID}`,
			text: [`Click on 'Create New ${tourID}' to add your ${tourID} to Paint Projex`],
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			attachTo: {
				element: '.primary',
				on: 'left',
			},
			modalOverlayOpeningPadding: 1,
			modalOverlayOpeningRadius: 7,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			beforeShowPromise: function() {
				return new Promise(function(resolve) {
					setTimeout(function() {
						window.scrollTo(0, 0);
						resolve(null);
					}, 1000);
				});
			},
			buttons: [
				{
					text: 'Next',
					action: function() {
						const element: HTMLElement = document.querySelector('.primary');
						element.click();
					},
				},
			],
			when: {
				show: () => {
					const navItem = document.querySelector(`.primary`);
					if (navItem) {
						navItem.addEventListener('click', handleNext);
					}
				},
				hide: () => {
					const navItem = document.querySelector(`.primary`);
					if (navItem) {
						navItem.removeEventListener('click', handleNext);
					}
				},
				cancel: () => {
					const navItem = document.querySelector(`.primary`);
					if (navItem) {
						navItem.removeEventListener('click', handleNext);
					}
				},
			},
		},
	];
}
