import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ProjectModel, UserModel } from '../../../../models';
import { ProjectTimelineComponent } from '../project-timeline/project-timeline.component';

@Component({
	selector: 'app-comment-preview',
	templateUrl: './comment-preview.component.html',
	styleUrls: ['./comment-preview.component.scss'],
})
export class CommentPreviewComponent implements OnInit, OnDestroy {
	@Input() public project: ProjectModel;
	@ViewChild('button') public button: ElementRef;
	@ViewChild('modal') public modal: ElementRef;
	@ViewChild('timeline') public timeline: ProjectTimelineComponent;

	public showDialog: boolean = false;
	private clickListener: Function;

	constructor(private renderer: Renderer2) {}

	public ngOnInit() {
		// subscribe to click handler
		//this.clickListener = this.renderer.listen('window', 'click', (e: MouseEvent) => {
		//	// hide the popup menu if the user has clicked outside the popup
		//	if ((!this.button || !this.button.nativeElement.contains(e.target)) && (!this.modal || !this.modal.nativeElement.contains(e.target))) {
		//		this.showDialog = false;
		//	}
		//});
	}

	public ngOnDestroy(): void {
		if (this.clickListener) {
			this.clickListener();
		}
	}

	public toggleModal(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
		this.showDialog = !this.showDialog;
	}
}
