import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProductTourComponent } from '../product-tour.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [ProductTourComponent],
	imports: [CommonModule, MatTooltipModule],
	exports: [ProductTourComponent],
	providers: [],
})
export class ProductTourModule {}
